import { baseTestnetTokens } from '@pancakeswap/tokens'
import { PoolCategory, SerializedConfigPool } from './types'

const pools: SerializedConfigPool[] = [
  {
    sousId: 1,
    stakingToken: baseTestnetTokens.cloud,
    earningToken: baseTestnetTokens.cloud,
    contractAddress: {
      97: '',
      56: '0x3b28a70a60253deC8056b6f4328E9B630dB09181',
      84531: "0x3b28a70a60253deC8056b6f4328E9B630dB09181"
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '500000000000000000',
    decimals: 18,
  }
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}))

export default pools