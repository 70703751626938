import { ChainId } from '@pancakeswap/sdk'
import FarmsBscPriceHelper from './56'
import FarmsBscTestnetPriceHelper from './97'
import FarmsEthereumPriceHelper from './1'
import FarmsGoerliPriceHelper from './5'
import FarmsOnusPriceHelper from'./1945'
import FarmsOnusMainnetPriceHelper from './1975'
import FarmsBaseTestPriceHelper from './84531'



export const getFarmsPriceHelperLpFiles = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.BSC:
      return FarmsBscPriceHelper
    case ChainId.BSC_TESTNET:
      return FarmsBscTestnetPriceHelper
    case ChainId.ETHEREUM:
      return FarmsEthereumPriceHelper
    case ChainId.GOERLI:
      return FarmsGoerliPriceHelper
    case ChainId.ONUS_TESTNET:
        return FarmsOnusPriceHelper
    case ChainId.ONUS:
          return FarmsOnusMainnetPriceHelper
    case ChainId.BASE_GOERLI:
        return FarmsBaseTestPriceHelper
    default:
      return []
  }
}
