import { ContextApi } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import {
    EarnFilledIcon,
    EarnFillIcon,
    EarnIcon, MenuItemsType, MoreIcon, NftFilledIcon, NftFillIcon, TradeFilledIcon, TradeIcon
} from '@pancakeswap/uikit'
import { LinkStatus } from '@pancakeswap/uikit/src/components/DropdownMenu/styles'
import { DropdownMenuItemType, DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { SUPPORT_CHAIN_CLOUDBASE, SUPPORT_CHAIN_OP } from 'utils/wagmi'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
    items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
    if (!chainId || !item.supportChainIds) {
        return item
    }
    if (item.supportChainIds?.includes(chainId)) {
        return item
    }
    return {
        ...item,
        disabled: true,
    }
}

const config: (
    t: ContextApi['t'],
    isDark: boolean,
    languageCode?: string,
    chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
        [
            {
                label: t('Trade'),
                icon: TradeIcon,
                fillIcon: TradeFilledIcon,
                href: '/swap',
                items: [
                    {
                        label: t('Swap'),
                        href: '/swap',
                          supportChainIds: SUPPORT_CHAIN_CLOUDBASE,
                    },
                    {
                        label: t('Liquidity'),
                        href: '/liquidity',
                    },
                    {
                        label: t('Bridge'),
                        href: 'https://bridge.base.org',
                        type: DropdownMenuItemType.EXTERNAL_LINK,
                        isActive: true,
                    },
                    {
                        label: t('Perpetual'),
                        href: '#',
                        disabled: true,
                    },
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('NFT Collections'),
                icon: NftFillIcon,
                fillIcon: NftFilledIcon,
                href: '#',
                items: [
                    {
                        label: t('CloudBase OG'),
                        href: 'https://airdrop.cloudbase.finance/',
                        type: 1
                    },
                    {
                        label: t('CloudBase Rising Dragon'),
                        href: 'https://mirror.xyz/0x838912bef2424E40919965DC7e0b02bA147E6744/nft/0xE296fc1770D0a94398273935DE8551Edf3425D04/0',
                        type: 1
                    },
                    {
                        label: t('CloudBase x Base Name Services'),
                        href: 'https://mirror.xyz/0x838912bef2424E40919965DC7e0b02bA147E6744/nYQTXpqkyuteDHWIpADIwcxRLWTtSIwuYCJBI9FKQJQ',
                        type: 1
                    },
                    {
                        label: t('Base Connector'),
                        href: 'https://mirror.xyz/0x838912bef2424E40919965DC7e0b02bA147E6744/nft/0x0D42d13e3E2f97b1589525Cd2f288cAAC79593Ae/0',
                        type: 1
                    },
                    {
                        label: t('Game On CloudBase'),
                        href: 'https://mirror.xyz/0x838912bef2424E40919965DC7e0b02bA147E6744/nft/0xC0D92594146A5d0612ef505bDaB829E1eAfDF3d1/0',
                        type: 1
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('Earn'),
                icon: EarnFillIcon,
                fillIcon: EarnFilledIcon,
                href: '/farms',
                items: [
                    {
                        label: t('Farms'),
                        href: '/farms',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE
                    },
                    {
                        label: t('Pools'),
                        href: '/pools',
                        supportChainIds: SUPPORT_CHAIN_CLOUDBASE
                    },
                    {
                        label: t('Tianlong Pool'),
                        href: '/tianlongpool',
                        supportChainIds: SUPPORT_CHAIN_OP,
                    },
                    {
                        label: t('Dragon Pool'),
                        href: '/dragonpool',
                        supportChainIds: SUPPORT_CHAIN_OP
                    },
                    {
                        label: t('BaseNS Cloud Pool'),
                        href: '/basenscloudpool',
                        supportChainIds: SUPPORT_CHAIN_OP
                    },
                    {

                        label: t('Base Connector Pool'),
                        href: '/feedpool',
                        supportChainIds: SUPPORT_CHAIN_OP
                    },
                    {
                        label: t('Tianlong Upgrade'),
                        href: '/tianlongupgrade',
                        supportChainIds: SUPPORT_CHAIN_OP,
                        status: { text: t('Live'), color: 'failure' }
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: t('Bridge'),
                icon: TradeIcon,
                fillIcon: TradeFilledIcon,
                href: 'https://bridge.cloudbase.finance',
                items: [
                    {
                        label: t('Base Bridge'),
                        href: 'https://bridge.base.org',
                        type: DropdownMenuItemType.EXTERNAL_LINK,
                        isActive: true,
                    },
                    {
                        label: t('Axelar Bridge'),
                        href: 'https://bridge.cloudbase.finance',
                        type: DropdownMenuItemType.EXTERNAL_LINK,
                        isActive: true,
                    }
                ].map((item) => addMenuItemSupported(item, chainId)),
            },
            {
                label: '',
                icon: MoreIcon,
                hideSubNav: true,
                disabled: true,
                items: [
                {
                    label: t('Airdrop'),
                    href: '/airdrop', 
                    },
                  {
                    label: t('LaunchPad'),
                    href: '#',
                    status: { text: t('Soon'), color: 'warning' },
                    disabled: true,
                  },
                  {
                    label: t('NFT MarketPlace'),
                    href: '#',
                    status: { text: t('Soon'), color: 'warning' },
                    disabled: true,
                  },
                  {
                    label: t('AMM NFT'),
                    href: '#',
                    status: { text: t('Soon'), color: 'warning' },
                    disabled: true,
                  }
                ].map((item) => addMenuItemSupported(item, chainId)),
              },

        ].map((item) => addMenuItemSupported(item, chainId))

export default config
