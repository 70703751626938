import { ChainId, Token } from '@pancakeswap/sdk'

export const baseTestnetTokens = {
    weth: new Token(
        ChainId.BASE_GOERLI,
        '0x4200000000000000000000000000000000000006',
        18,
        'WETH',
        'WETH Token',
        '',
    ),
    usdt: new Token(
        ChainId.BASE_GOERLI,
        '0xD64a51459715C32BB3C54Ab1dE619e6cc158fBFf',
        18,
        'USDT',
        'USDT Token',
        '',
    ),
    cloud: new Token(
        ChainId.BSC,
        '0x8ae3d0E14Fe5BC0533a5Ca5e764604442d574a00',
        18,
        'CLOUD',
        'CLOUD BASE',
        'https://cloudbase.finance/',
    ),
    punkbase : new Token(
        ChainId.BASE_GOERLI,
        '0xeb31E4B4beDfb0df00B5dA90f30501d263b37691',
        18,
        'PunkBase',
        'PunkBase Token',
        '',
    ),
    usdc: new Token(
        ChainId.BASE_GOERLI,
        '0x7b4Adf64B0d60fF97D672E473420203D52562A84',
        18,
        'USDC',
        'USDC Token',
        '',
    ),

}
