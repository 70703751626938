import { ChainId, Token } from '@pancakeswap/sdk'

export const baseMainnetToken = {
    cloud: new Token(
        ChainId.BASE_MAINNET,
        '0xE7e62B43fefd74b8D802dCa7B5f58413d6e873c5',
        18,
        'CLOUD',
        'CLOUD Token',
        '',
    ),
}
